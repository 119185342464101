import React from 'react'
import { NavLink } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Footer from '../components/Footer'
import Button from '../components/common/Button'
import { Article, Logo, LogOut, Vector } from '../components/common/Svgs'
import { useAuth } from '../hooks/useAuth'

export default function Home() {
    const auth = useAuth()
    return (
        <div>
            <div className="home__page">
                <div className="navbar-brand">
                    <Logo />
                </div>
                <div className="container">
                    <div className="header">
                        <div className="nav" role="navigation" aria-label="main navigation">
                            <div className="nav__items">
                                <div className="navbar-start is-flex is-flex-direction-row">
                                    <NavLink to="/">
                                        <p className="hover" style={{ color: '#F7941D' }}>Home</p>
                                    </NavLink>
                                    <NavLink to="/leaderboard">
                                        <p className="hover">Leaderboards</p>
                                    </NavLink>
                                    <NavLink to="/submit-video">
                                        <p className="hover">Let’s Play</p>
                                    </NavLink>
                                    {auth.user ? (
                                        <NavLink
                                            to="/sign-in" className={cn('mr-3 leader__level_item hover',
                                                css(styles.logout))}>
                                            <i onClick={() => auth.signOut()} className="is-flex is-align-items-center">
                                                Log out&nbsp;&nbsp;
                                                <LogOut />
                                            </i>
                                        </NavLink>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="content__top is-flex">
                            <div className="top_left ml-4">
                                <p className="top_text">The Challenge That </p>
                                <p className="top_text"> Sharpens Your Skills</p>
                                <p className="subtitle">From Deep 3PT Contest</p>
                                <p className="vectors">SHOOT <Vector />   UPLOAD <Vector />   WIN</p>
                                <div className="level">
                                    <NavLink className="level-item" to="/leaderboard">
                                        <Button
                                            className="button__rules_second"
                                            text="Check out the competition" />
                                    </NavLink>
                                    <NavLink className="level-item" to="/contest-rules">
                                        <Button
                                            className="button__rules" text="Contest rules" />
                                    </NavLink>
                                </div>
                            </div>

                            <div className="top_right article">
                                <Article />
                            </div>
                        </div>
                        <div className="columns content__bottom">
                            <div className="column content__bottom_item">
                                <h3>ELEVATE YOUR GAME</h3>
                                <p>No special tech necessary, all you need is a phone and a buzzer beater</p>
                            </div>
                            <div className="column content__bottom_item">
                                <h3>PLAY WITH YOUR FRIENDS</h3>
                                <p>Challenge anyone to beat your streak. Be the champ of your squad</p>
                            </div>
                            <div className="column content__bottom_item">
                                <h3>SHOWCASE YOUR JUMPER</h3>
                                <p>Compete with players around the world, and win</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>

    )
}

const styles = StyleSheet.create({
    logout: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    activeLink: {
        color: 'orange'
    },
})
