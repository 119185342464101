import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyC_J3ClP2gUlCuphDpFpcB8gRZM2yCZldQ',
    authDomain: 'basketball-dev-abd27.firebaseapp.com',
    projectId: 'basketball-dev-abd27',
    storageBucket: 'basketball-dev-abd27.appspot.com',
    messagingSenderId: '525516302293',
    appId: '1:525516302293:web:d94f748a36bfc68da83f5c',
    measurementId: 'G-VCQTFYZV2N',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const firebaseApp =  firebase
export const auth = firebase.auth()
export const db = firebase.firestore()

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider)
        .then(data=>{
            console.log(data)
        })
        .catch((err)=>{
            console.log(err);
        })
}

const provider1 = new firebase.auth.FacebookAuthProvider()
provider1.setCustomParameters({ prompt: 'select_account' })

export const signInWithFacebook = () => {
    auth.signInWithPopup(provider1)
        .then(data=>{
            console.log(data)
        })
        .catch((err)=>{
            console.log(err);
        })
}
export default firebase