import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import cn from 'classnames'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import { googleProvider } from '../config/authMethods'
import { Facebook, Google, Logo } from '../components/common/Svgs'
import socialMediaAuth from '../service/auth'
import { email, maxLength, minLength, required, validator } from '../utils/validators'
import { useAuth } from '../hooks/useAuth'
import line from '../static/images/Line 12.svg'
import Footer from '../components/Footer'
import { signInWithFacebook } from '../service/facebook'
import {firebaseApp} from "../service/firebase";

export default function SignUp() {
    const auth = useAuth()
    const history = useHistory()
    const handleOnClick = async (provider) => {
        await socialMediaAuth(provider)
    }
    async function onSubmit(data) {
        const success = await auth.signup(data.email, data.password, data.name)
        if (success) {
            setRegister(false)

            const ref = firebaseApp.firestore().collection('users');

            ref
                .doc(firebaseApp.auth().currentUser.uid).get()
                .then((docSnapshot) => {
                    if(docSnapshot.exists){
                        ref.doc(firebaseApp.auth().currentUser.uid)
                        .update({
                            name: data.name,
                            email: firebaseApp.auth().currentUser.email,
                            last_login: Date.now()
                        })
                        .catch((err) => {console.log(err)})
                    } else {
                        ref.doc(firebaseApp.auth().currentUser.uid)
                            .set({
                                name: data.name,
                                email: firebaseApp.auth().currentUser.email,
                                last_login: Date.now()
                            })
                            .then(() => { })
                            .catch((err) => {
                            console.log(err);
                            });

                    }
                })



            console.log(ref)

            history.push('/submit-video')
        }
    }
    const [register, setRegister] = useState(true)

    return (
        <div>
            <div className="leaderboard">
                <div style={{ position: 'absolute', top: 0 }} className="">
                    <Logo />
                </div>
                <div className="container">
                    <nav
                        style={{ paddingLeft: 100 }}
                        className="is-flex is-justify-content-flex-end section content has-text-right">
                        <div className="navbar-end is-flex is-flex-direction-row">
                            <NavLink
                                to="/"
                                className={cn('leader__level_item mr-4 hover', css(styles.secondText))}>
                                Home
                            </NavLink>
                            <NavLink
                                to="/leaderboard"
                                className={cn('leader__level_item mr-4 hover', css(styles.secondText))}>
                                Leaderboards
                            </NavLink>
                            <NavLink
                                to="/submit-video"
                                className={cn('leader__level_item mr-4 hover', css(styles.navbarText))}>
                                Let’s Play
                            </NavLink>

                        </div>
                    </nav>
                    <div className="hero">
                        <div className="test">
                            <div className="columns">
                                <div className="column is-6">
                                    <p className={css(styles.text)}>Submit Video</p><img src={line} alt="" />
                                    <p className="mb-6">Home / Submit Video</p>
                                    <p className={cn('', css(styles.title))}>
                                        The From Deep 3pt Contest is free for
                                        <br /> anyone to enter, no strings attached
                                    </p>
                                    <p className={css(styles.step)}>Step 1</p>
                                    <p className={css(styles.stepText)}>
                                        Create a 5-minute video of you shooting from behind a high <br /> school,
                                        college,or pro 3pt line.
                                        Count your number of 3s <br /> made and submit your # of makes with your video
                                    </p>
                                    <p className={css(styles.step)}>Step 2</p>
                                    <p className={css(styles.stepText)}>
                                        We will watch your video, confirm your makes, and put you
                                        <br /> on the leaderboard
                                    </p>
                                    <p className={css(styles.step)}>Step 3</p>
                                    <p className={css(styles.stepText)}>
                                        From Deep is a weekly tournament, if you don't win
                                        this <br /> week, submit again on Monday!
                                    </p>
                                </div>
                                <div style={{}} className="column is-6">
                                    <Formik onSubmit={onSubmit}
                                        initialValues={{
                                            email: '',
                                            password: '',
                                            name: '',
                                        }}>
                                        <Form>
                                            <p className={css(styles.textRight)}>
                                                Sign up with social media or fill the forms below
                                            </p>
                                            <div className="column is-3 is-flex">
                                                <span
                                                    onClick={signInWithFacebook}
                                                    className="mr-2"><Facebook />
                                                </span>
                                                {/* <span className="mr-2"><Twitter /></span> */}
                                                {/* <span className="mr-2"><Instagram /></span> */}
                                                <span
                                                    onClick={() => handleOnClick(googleProvider)}
                                                    className="mr-2"><Google />
                                                </span>
                                                {/* <span */}
                                                {/*    onClick={() => handleOnClick(appleProvider)} */}
                                                {/*    className="mr-2"> */}
                                                {/*    <Apple /> */}
                                                {/* </span> */}
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <p className={css(styles.label)}>NAME</p>
                                                    <Input
                                                        className={css(styles.input)}
                                                        name="name"
                                                        validate={validator(required, minLength(1), maxLength(100))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <p className={css(styles.label)}>
                                                        EMAIL <span style={{ color: '#F7941D' }}>*</span>
                                                    </p>
                                                    <Input
                                                        name="email"
                                                        className={cn('', css(styles.input))}
                                                        validate={validator(email, required)}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <p className={css(styles.label)}>
                                                        PASSWORD <span style={{ color: '#F7941D' }}>*</span>
                                                    </p>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        className={css(styles.input)}
                                                        validate={validator(required, minLength(6), maxLength(30))}
                                                    />
                                                </div>

                                            </div>
                                            <div className="columns">
                                                <div className="column content has-text-right">
                                                    <Button
                                                        loading={auth.loading}
                                                        type="submit"
                                                        style={{ backgroundColor: '#F7941D',
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            borderColor: '#F7941D',
                                                            borderRadius: 14 }}
                                                        className="ml-2"
                                                        text="Sign up" />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

const styles = StyleSheet.create({
    text: {
        fontWeight: 'bold',
        fontSize: 30,
        letterSpacing: 0.35,
        color: '#ffffff',
    },
    textRight: {
        fontWeight: 'bold',
        fontSize: 18,
        letterSpacing: 0.35,
        color: '#ffffff',
    },
    navbarText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
        color: '#F7941D',
    },
    logout: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    secondText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    header: {
        backgroundColor: '#585B6B',
        color: 'white',
        fontWeight: 'normal',
        borderColor: '#525252',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: '110%',
        letterSpacing: 0.35,
        color: 'white',
        marginBottom: 10,
    },
    step: {
        fontSize: 22,
        fontWeight: 'bold',
        letterSpacing: 0.35,
        color: '#545862',
    },
    stepText: {
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.35,
        color: '#ffffff',
        textAlign: 'left',
        marginBottom: 10,
    },
    input: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        // width: 270,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',
    },
    inputBig: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        width: 577,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',

    },
    label: {
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 0.35,
        color: 'white',
        // marginBottom: 5,
        marginLeft: 10,

    },
})
