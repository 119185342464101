import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { Facebook, Google, Instagram, Tiktok, Twitter } from './common/Svgs'

export default function Footer() {
    return (
        <div className={cn('footer__all', css(styles.footer))}>
            <div className="container is-flex is-justify-content-center py-4">
                <div className="is-flex is-6">
                    <div className="footer-icon">
                        <div className="is-flex">
                            <a target='_blank' href='https://www.facebook.com/FromDeepContest' className="mr-3"><Facebook /></a>
                            <a target='_blank'href='https://twitter.com/fromdeepcontest' className="mr-3"><Twitter /></a>
                            <a target='_blank'  href='https://www.instagram.com/fromdeepcontest/' className="mr-3"><Instagram  /></a>
                            {/* <a target='_blank' className="mr-3"><Google /></a> */}
                            {/* <a target='_blank' className="mr-3"><Tiktok /></a> */}
                        </div>

                        <span className="footer-text">© From Deep 3PT Contest 2021. All rights reserved</span>

                    </div>
                </div>
           
            </div>
        </div>
    )
}
const styles = StyleSheet.create({
    footer: {
        backgroundColor: '#111215',
        position: '',
        bottom: 0,
        width: '100%',
        paddingTop: 12,
    },
})
