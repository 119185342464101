import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import Input from './common/Input'
import Button from './common/Button'
import { maxLength, minLength, number, required, validator } from '../utils/validators'
import Progress from './common/Progress'

export default function SubmitVideoForm({ onSubmit, initialValues, loading, percentage, name }) {
    const [videos, setVideos] = useState({})

    async function onSuccess(data, actions) {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('shotsToken', data.shotsToken)
        formData.append('shotsMode', data.shotsMode)
        formData.append('video', videos.video)
        onSubmit(formData, actions)
    }

    return (
        <Formik
            onSubmit={onSuccess}
            initialValues={{ title: '', shotsToken: '', shotsMode: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <p className={css(styles.label)}>VIDEO TITLE <span style={{ color: '#F7941D' }}>*</span></p>
                        <Input
                            validate={validator(required, minLength(1), maxLength(100))}
                            name="title"
                            className={css(styles.inputBig)}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <p className={css(styles.label)}>SHOTS TAKEN <span style={{ color: '#F7941D' }}>*</span></p>
                        <Input
                            name="shotsToken"
                            className={css(styles.input)}
                            validate={validator(required, number)}
                        />
                    </div>
                    <div className="column">
                        <p className={css(styles.label)}>SHOTS MADE <span style={{ color: '#F7941D' }}>*</span></p>
                        <Input
                            validate={validator(required, number)}
                            name="shotsMode"
                            className={css(styles.input)}
                        />
                    </div>
                </div>
                {loading ? (
                    <div className="mb-4"><Progress text={`${percentage}%`} value={percentage} /></div>
                ) : ''}
                <div className="columns">
                    <div className="column is-flex">
                        <button className={cn('button', css(styles.file))}>
                            <input
                                accept="video/*,"
                                className="file-input"
                                onChange={(e) => setVideos({ ...videos, video: e.target.files[0] })}
                                type="file" />Choose file
                        </button>
                        {name ? (
                            <p className="ml-3 mt-1" style={{ color: 'white', fontSize: 12 }}>
                                {videos.video ? videos.video.name : ''}
                            </p>
                        ) : ''}

                    </div>
                    <div className="content has-text-right column">
                        <Button
                            loading={loading}
                            className={cn('', css(styles.submit))}
                            type="submit"
                            text="Submit video" />
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    fileSize: {
        fontSize: 14,
    },
    input: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        // width: 270,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',
    },
    inputBig: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        // width: 577,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',

    },
    label: {
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 0.35,
        color: 'white',
        marginBottom: 5,
        marginLeft: 10,

    },
    submit: {
        backgroundColor: '#F7941D',
        fontWeight: 'bold',
        color: 'white',
        borderColor: '#F7941D',
        borderRadius: 14,
    },
    file: {
        fontWeight: 'bold',
        backgroundColor: 'black',
        color: 'white',
        borderColor: '#F7941D',
        borderRadius: 14,
    },
})
