import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { Logo, LogOut } from '../components/common/Svgs'
import Footer from '../components/Footer'
import line from '../static/images/Line 12.svg'
import SubmitVideoCreate from '../components/SubmitVideoCreate'
import { useAuth } from '../hooks/useAuth'

export default function SubmitVideo() {
    const auth = useAuth()
    return (
        <div>
            <div className="leaderboard">
                <div style={{ position: 'absolute', top: 0 }} className="">
                    <Logo />
                </div>
                <div className="container">
                <nav
                    className="is-flex is-justify-content-flex-end section content has-text-right bord-nav">
                    <div className="bord-item">
                        <NavLink
                            to="/"
                            className={cn('leader__level_item mr-5 mb-3 hover', css(styles.secondText))}>
                            Home
                        </NavLink>
                        <NavLink 
                            activeClassName='orange'
                            to='/leaderboard'
                            className={cn('leader__level_item mr-5 mb-3  hover', css(styles.secondText))}>
                            Leaderboards
                        </NavLink>
                        <NavLink
                            style={{
                                marginBottom: 10
                            }}
                          
                            to="/submit-video"
                            className={cn('leader__level_item mr-5   hover', css(styles.activeLink))}>
                            Let’s Play
                        </NavLink>
                        {auth.user ? (
                            <NavLink
                                to="/sign-in" className={cn('mr-3 leader__level_item hover',
                                    css(styles.logout))}>
                                <i onClick={() => auth.signOut()} className="is-flex is-align-items-center">
                                    Log out &nbsp;&nbsp;
                                    <LogOut />
                                </i>
                            </NavLink>
                        ) : ''}
                    </div>
                </nav>
                    <div className="hero">
                        <div className="test">
                            <div className="columns">
                                <div className="column is-6">
                                    <p className={css(styles.text)}>Submit Video</p><img src={line} alt="" />
                                    <p className="mb-6">Home / Submit Video</p>
                                    <p className={cn('', css(styles.title))}>
                                        The From Deep 3pt Contest is free for
                                        <br /> anyone to enter, no strings attached
                                    </p>
                                    <p className={css(styles.step)}>Step 1</p>
                                    <p className={css(styles.stepText)}>
                                        Create a 5-minute video of you shooting from behind a high <br /> school,
                                        college,or pro 3pt line.
                                        Count your number of 3s <br /> made and submit your # of makes with your video
                                    </p>
                                    <p className={css(styles.step)}>Step 2</p>
                                    <p className={css(styles.stepText)}>
                                        We will watch your video, confirm your makes, and put you
                                        <br /> on the leaderboard
                                    </p>
                                    <p className={css(styles.step)}>Step 3</p>
                                    <p className={css(styles.stepText)}>
                                        From Deep is a weekly tournament, if you don't win
                                        this <br /> week, submit again on Monday!
                                    </p>
                                </div>
                                <div className="column is-6">
                                    <SubmitVideoCreate />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    )
}

const styles = StyleSheet.create({
    text: {
        fontWeight: 'bold',
        fontSize: 30,
        letterSpacing: 0.35,
        color: '#ffffff',
    },
    textRight: {
        fontWeight: 'bold',
        fontSize: 18,
        letterSpacing: 0.35,
        color: '#ffffff',
    },
    navbarText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
        color: '#F7941D',
    },
    logout: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    secondText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    activeLink: {
        color: 'orange'
    },
    header: {
        backgroundColor: '#585B6B',
        color: 'white',
        fontWeight: 'normal',
        borderColor: '#525252',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: '110%',
        letterSpacing: 0.35,
        color: 'white',
        marginBottom: 10,
    },
    step: {
        fontSize: 22,
        fontWeight: 'bold',
        letterSpacing: 0.35,
        color: '#545862',
    },
    stepText: {
        fontSize: 14,
        fontWeight: 'normal',
        letterSpacing: 0.35,
        color: '#ffffff',
        textAlign: 'left',
        marginBottom: 10,
    },
    input: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        // width: 270,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',
    },
    inputBig: {
        backgroundColor: 'rgba(49, 52, 64, 0.26)',
        boxSizing: 'border-box',
        width: 577,
        height: 50,
        borderWidth: 0.5,
        borderColor: '#525252',
        borderRadius: 14,
        color: 'white',

    },
    label: {
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 0.35,
        color: 'white',
        // marginBottom: 5,
        marginLeft: 10,

    },
})
