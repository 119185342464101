import firebase from 'firebase/compat'

const firebaseConfig = {
    apiKey: 'AIzaSyC_J3ClP2gUlCuphDpFpcB8gRZM2yCZldQ',
    authDomain: 'basketball-dev-abd27.firebaseapp.com',
    projectId: 'basketball-dev-abd27',
    storageBucket: 'basketball-dev-abd27.appspot.com',
    messagingSenderId: '525516302293',
    appId: '1:525516302293:web:d94f748a36bfc68da83f5c',
    measurementId: 'G-VCQTFYZV2N',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default firebase
