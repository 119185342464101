import React, { useState, useEffect, useContext, createContext } from 'react'
import 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

firebase.initializeApp({
    apiKey: 'AIzaSyC_J3ClP2gUlCuphDpFpcB8gRZM2yCZldQ',
    authDomain: 'basketball-dev-abd27.firebaseapp.com',
    projectId: 'basketball-dev-abd27',
    storageBucket: 'basketball-dev-abd27.appspot.com',
    messagingSenderId: '525516302293',
    appId: '1:525516302293:web:d94f748a36bfc68da83f5c',
    measurementId: 'G-VCQTFYZV2N',
})

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    const login = (email, password) => firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
            setUser(response.user)
            return response.user
        })

    const signup = (email, password) => firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
            setUser(response.user)
            return response.user
        })

    const signOut = () => firebase
        .auth()
        .signOut()
        .then(() => {
            setUser(false)
        })
    const google = () => firebase
        .auth()
        .signInWithPopup(provider)
        .then((response) => response.user)

    const sendPasswordResetEmail = (email) => firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => true)

    const confirmPasswordReset = (code, password) => firebase
        .auth()
        .confirmPasswordReset(code, password)
        .then(() => true)

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            setUser(user)
            setIsAuthenticating(false)
        })

        return () => unsubscribe()
    }, [])

    const values = {
        user,
        isAuthenticating,
        login,
        signup,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
    }

    return (
        <AuthContext.Provider value={values}>
            {!isAuthenticating && children}
        </AuthContext.Provider>
    )
}
