import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'

export default function LeaderboardWeek({ item }) {

    return (
        <tr>
            <td className={cn('has-text-left', css(styles.td))}>
                {item.contestant}
            </td>
            <td className={cn('has-text-left', css(styles.td))}>
                {item.video}
            </td>
            <td className={cn('has-text-left', css(styles.td))}>
                {item.first}
            </td>
            <td className={cn('has-text-left', css(styles.td))}>
                {item.second}
            </td>
            <td className={cn('has-text-left', css(styles.td))}>
                {item.third}
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    td: {
        backgroundColor: '#313440',
        color: 'white',
        borderColor: '#525252',
    },
})
