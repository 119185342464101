import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyC_J3ClP2gUlCuphDpFpcB8gRZM2yCZldQ',
    authDomain: 'basketball-dev-abd27.firebaseapp.com',
    projectId: 'basketball-dev-abd27',
    storageBucket: 'basketball-dev-abd27.appspot.com',
    messagingSenderId: '525516302293',
    appId: '1:525516302293:web:d94f748a36bfc68da83f5c',
    measurementId: 'G-VCQTFYZV2N',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

const provider = new firebase.auth.FacebookAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const signInWithFacebook = () => auth.signInWithPopup(provider)
export default firebase
