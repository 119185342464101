import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Tabs, useTabState, usePanelState } from '@bumaga/tabs'
import { NavLink } from 'react-router-dom'
import { Logo, LogOut } from '../components/common/Svgs'
import Footer from '../components/Footer'
import line from '../static/images/Line 12.svg'
import Table from '../components/common/Table'
import LeaderboardWeek from '../components/LeaderboardWeek'
import LeaderboardAllTime from '../components/LeaderboardAllTime'
import { useAuth } from '../hooks/useAuth'

import { useState, useEffect } from 'react'
import {db, auth} from '../service/firebase'


export default function Leaderboard() {

// ..................................................................
    const [weekList, setWeekList] = useState([])
    const [allList, setAllList] = useState([])

    console.log(weekList)
    console.log(allList)


    useEffect(() => {
        getLeadersList()
    }, [])

    // let curr = new Date;
    // let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    // let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()-6))



    const  getLeadersList = () => {
		db.collection("uploaded_videos")
            .get()
            .then(snapchot => {
                const weekLeaders = []
                const allLeaders = []
                snapchot.forEach(doc => {
                    weekLeaders.push(_transformLeaders(doc.data()))
                    allLeaders.push(_transformLeaders(doc.data()))
                })
                // const weekUploaded = weekLeaders.slice().sort((a, b) => Date.parse(b.uploaded) - Date.parse(a.uploaded))
                // const allUploaded = allLeaders.slice().sort((a, b) => Date.parse(a.uploaded) - Date.parse(b.uploaded))
                const weekPercent = weekLeaders.slice().sort((a, b) => b.first - a.first)
                const weekDuration = weekPercent.slice().sort((a, b) => b.uploaded -  a.uploaded)
                const allPercent = allLeaders.slice().sort((a, b) => b.first -  a.first)
                // console.log(weekDuration)
                // const weekDuration = weekPercent.filter(item => Date.parse(item.uploaded) <= Date.parse(lastday) && Date.parse(item.uploaded) >= Date.parse(firstday))

                
                setWeekList(weekDuration)
                setAllList(allPercent)
            })
	};

    const _transformLeaders =  (data) => {
        return  {
            id: data.id,
            uploaded: Date.parse(data.uploaded) ,
            contestant: data.user,
            video: data.title,
            first: data.successPercent,
            second: data.shotsTaken,
            third: data.shotsMade,
            // videoLink: data.video.location,
        }
    }


    
    // ..........................................................................................
    
    const [active, toggleActive] = useState(1)

    function toggleTab(index) {
        toggleActive(index)
    }

    const [displayList, setList] = useState(true)

    const auth = useAuth()

    return (
        <div className="leaderboard">
            <div style={{ position: 'absolute' }} className="">
                <Logo />
            </div>
            <div 
            className="container">
                <nav
                    className="is-flex is-justify-content-flex-end section content has-text-right bord-nav">
                    <div className="bord-item">
                        <NavLink
                            to="/"
                            className={cn('leader__level_item mr-5 mb-3 hover', css(styles.navbarText))}>
                            Home
                        </NavLink>
                        <NavLink 
                            activeClassName='orange'
                            to='/leaderboard'
                            className={cn('leader__level_item mr-5 mb-3  hover', css(styles.activeLink))}>
                            Leaderboards
                        </NavLink>
                        <NavLink
                            style={{
                                marginBottom: 10
                            }}
                          
                            to="/submit-video"
                            className={cn('leader__level_item mr-5   hover', css(styles.navbarText))}>
                            Let’s Play
                        </NavLink>
                        {auth.user ? (
                            <NavLink
                                to="/sign-in" className={cn('mr-3 leader__level_item hover',
                                    css(styles.logout))}>
                                <i onClick={() => auth.signOut()} className="is-flex is-align-items-center">
                                    Log out &nbsp;&nbsp;
                                    <LogOut />
                                </i>
                            </NavLink>
                        ) : ''}
                    </div>
                </nav>
            </div>
            <div className="hero container">
                <div className="testsecond">
                    <div className="columns">
                            

                        <div className="column">
                            <Tabs>
                                <div className="column">
                                    <p className={css(styles.text)}>Leaderboards</p>
                                    <img src={line} alt="" />
                                    <p className="mb-6">Home / Leaderboard</p>
                                    <div
                                        className={cn('pointer', css(styles.tab))}>
                                        <button
                                            className='activePeriod' 
                                            onClick={() => {
                                                setList(true);
                                                toggleTab(1)
                                            }}
                                            style={{
                                                background: active === 1 ? '#F7941D' : ''
                                            }}
                                        >
                                            <p>All</p>
                                        </button>

                                        <button
                                            className='activePeriod'
                                            onClick={() => {
                                                setList(false)
                                                toggleTab(2)
                                            }}
                                            style={{
                                                background: active === 2 ? '#F7941D' : ''
                                            }}
                                        >
                                            <p>Week</p>
                                            
                                        </button>
                                    </div>
                                </div>

                                <div style={{ borderColor: 'red' }} className="column is-7 pointer mobile__table">
                                    <div style={{ width: 0, height: 33 }} />
                                    
                                        {displayList && weekList.length > 0  ? (

                                            <Table
                                                totalCount={weekList.length}
                                                headerStyle={styles.header}
                                                headerTextColor={styles.header}
                                                loading={weekList.loading}
                                                items={weekList}
                                                columns={{
                                                contestant: 'Contestant',
                                                video: 'Video',
                                                first: '3FG%',
                                                second: '3PA',
                                                third: '3P',
                                            }}
                                            renderItem={(weekList) => (
                                                <LeaderboardWeek
                                                    key={weekList.id}
                                                    item={weekList} 
                                                />
                                            )}
                                                />

                                            ) : 
                                        
                                            <Table
                                                headerStyle={styles.header}
                                                headerTextColor={styles.header}
                                                loading={allList.loading}
                                                items={allList}
                                                columns={{
                                                    contestant: 'Contestant',
                                                    video: 'Video',
                                                    first: '3FG%',
                                                    second: '3PA',
                                                    third: '3P',
                                                }}
                                                renderItem={(allList) => (
                                                    <LeaderboardAllTime
                                                        key={allList.id}
                                                        item={allList} 
                                                    />
                                                )}
                                            />
                                        }
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

const styles = StyleSheet.create({
    text: {
        fontWeight: 'bold',
        fontSize: 30,
        letterSpacing: 0.35,
        color: '#ffffff',
    },
    navbarText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
    },
    activeLink: {
        color: 'orange'
    },
    secondText: {
        fontSize: 15,
        fontWeight: '500',
        letterSpacing: 0.35,
        color: '#585b6b42',
        backgroundColor: '#111215',
        ':hover': {
            color: '#4f5262',
        },
    },
    header: {
        background: '#4f5262',
        color: 'white',
        fontWeight: 'normal',
        borderColor: '#525252',
    },
    isActive: {
        backgroundColor: '#F7941D',
        color: 'white',
        width: 85,
        height: 40,
        borderRadius: 20,
        textAlign: 'center',
        paddingTop: 7,

    },
    notActive: {
        backgroundColor: '#585B6B',
        color: 'white',
        width: 85,
        height: 40,
        borderRadius: 20,
        textAlign: 'center',
        paddingTop: 7,
    },
    time: {
        color: 'white',
        width: 77,
        height: 33,
        borderRadius: 20,
        textAlign: 'center',
        paddingTop: 3,

    },
    tab: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 170,
        height: 33,
        background: 'rgba(88, 91, 107, 0.26)',
        // backgroundColor: '#585B6B',
        borderRadius: 20,
    },
})
