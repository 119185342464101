import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { GlobalProvider } from './contexts/GlobalContext'
import Home from './pages/Home'
import Leaderboard from './pages/Leaderboard'
import SubmitVideo from './pages/SubmitVideo'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import PrivateRoute from './components/common/PrivateRoute'
import ContestRules from './pages/ContestRules'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `Fromdeepcontest`
    }, [])

    return (
        <GlobalProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/leaderboard" component={Leaderboard} exact />
                        <Route path="/contest-rules" component={ContestRules} exact />
                        <Route path="/sign-in" component={SignIn} exact />
                        <Route path="/sign-up" component={SignUp} exact />
                        <PrivateRoute path="/submit-video" exact><SubmitVideo /></PrivateRoute>
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </GlobalProvider>
    )
}
