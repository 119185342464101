import React, { useState } from 'react'
import S3 from "react-aws-s3";
import { firebaseApp, auth } from '../service/firebase'
import { usePostRequestFile } from '../hooks/request'
import { SUBMITVIDEO } from '../urls'
import SubmitVideoForm from './SubmitVideoForm'
import { useMessage } from '../hooks/message'

import { v4 as uuidv4 } from 'uuid'

export default function SubmitVideoCreate({ onCancel }) {
    const [percentage, setPercentage] = useState(0)
    const [name, setName] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showMessage] = useMessage()
    const config = {
        onUploadProgress:function (progressEvent) {
            const { loaded, total } = progressEvent
            const percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                setPercentage(percent)
            }
        },
    }

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); 
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    async function onSubmit(formData, actions) {
        const s3Config = {
          bucketName: "fromdeep",
          dirName: "videos",
          region: "us-east-2",
          accessKeyId: "AKIAQVYNKKSUPKFINL5Y",
          secretAccessKey: "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",
        };


        let file = formData.get("video");
        let newFileName = `Video-${Math.random()}${Date.now()}.webm`;



        const ReactS3Client = new S3(s3Config);
        setLoading(true);
        let start = 10;
        setPercentage(start)
        let timerId = setInterval(() => {
            start = start + 5
            if (start <= 100) {
                setPercentage(start)
            } else {
                start = 50;
                setPercentage(start);
            }
        }, 500);
        ReactS3Client.uploadFile(file, newFileName)
            .then((data) => {
              if (data.status === 204) {
                  postVideoID({
                      id: uuidv4(),
                      isVerified: false,
                      title: formData.get("title"),
                      shotsTaken: formData.get("shotsToken"),
                      shotsMade: formData.get("shotsMode"),
                      successPercent: (Number(formData.get("shotsMode")) / (Number(formData.get("shotsToken"))) * 100).toFixed(1),
                      uploaded: today = mm + '/' + dd + '/' + yyyy,
                      user: auth.currentUser.email,
                      video: {...data}
                  }, actions);
                  clearInterval(timerId);
              } else {
                showMessage('There were some error', 'is-danger')
              }
        });

        // await submitVideoCreate.request({ data: formData });
    }

    const postVideoID = (docData, actions) => {
        console.log(docData)
        const ref = firebaseApp.firestore().collection('uploaded_videos');
        ref.add({
              ...docData
          })
          .then(() => {
              showMessage('Your video has been sent', 'is-success');
              setName(false);
              actions.resetForm();
              setLoading(false);
          })
          .catch((err) => {
            alert(err);
          });
    }

    return (
        <div>
            <SubmitVideoForm
                name={name}
                percentage={percentage}
                onSubmit={onSubmit}
                onCancel={onCancel} l
                loading={loading} />
        </div>
    )
}
